import React, { useState, useEffect } from "react";
import s from '../styles/Offers.module.css';

const OffersList = () => {
  const [offers, setOffers] = useState([]);
  const [referallinks, setReferallinks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refresh, setRefresh] = useState(false);


  // Состояние для редактирования названия ссылки
  const [editId, setEditId] = useState(null);
  const [newRefName, setNewRefName] = useState("");

  useEffect(() => {
    // Запрос офферов с бэкенда
    fetch("/api/offers")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch offers");
        }
        return response.json();
      })
      .then((data) => {
        setOffers(data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    // Запрос реферальных ссылок с бэкенда
    fetch("/api/referallinks")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch referral links");
        }
        return response.json();
      })
      .then((referallinksData) => {
        setReferallinks(referallinksData);
        setLoading(false);
        
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, [refresh]);

  const createReferralLink = async (offerId) => {
    try {
      const response = await fetch(`/api/create_referral_link`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ offer_id: offerId }),
      });
  
      if (!response.ok) {
        throw new Error("Не удалось создать реферальную ссылку.");
      }
  
      const data = await response.json();
      setRefresh(!refresh);
    } catch (error) {
      console.error(error);
      alert("Ошибка при создании реферальной ссылки.");
    }
  };

  const deleteReferralLink = async (linkId) => {
    const confirmation = window.confirm("Вы уверены, что хотите удалить эту реферальную ссылку?");
  if (!confirmation) {
    return; // Если пользователь отменил, прерываем выполнение функции
  }
    try {
      const response = await fetch(`/api/delete_referral_link`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ link_id: linkId }),
      });
  
      if (!response.ok) {
        throw new Error("Не удалось удалить реферальную ссылку.");
      }
  
      const data = await response.json();
      setRefresh(!refresh);
    } catch (error) {
      console.error(error);
      alert("Ошибка при удалении реферальной ссылки.");
    }
  };

  // 5) Обновление названия ссылки (Редактирование)
  const updateRefName = async (id, updatedName) => {
    try {
      // Отправляем запрос на сервер
      const response = await fetch("/api/update_ref_name", {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ id, ref_name: updatedName }),
      });

      if (!response.ok) {
        throw new Error("Не удалось обновить название ссылки");
      }

      const data = await response.json();
      if (data.success) {
        alert("Название ссылки обновлено!");
        setRefresh(!refresh); // Перезагружаем список
      } else {
        alert(data.error || "Ошибка при обновлении ссылки");
      }
    } catch (error) {
      console.error(error);
      alert("Ошибка при обновлении названия ссылки.");
    }
  };
  

  if (loading) return <p>Загрузка офферов...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
    <div class={s.offers}>
      <h2>Доступные офферы:</h2>
      {offers.length > 0 ? (
        <ul class={s.offers_ul}>
          {offers.map((offer) => (
            <li key={offer.id} class={s.offers_li}>
              <h3>{offer.name}</h3>
              <p>{offer.description}</p>
              <p>Тип оффера: {offer.typecpa}</p>
              <p>Сайт: <a  href={offer.url} target="_blank">{offer.url}</a></p>
              <button
                    onClick={() => createReferralLink(offer.id)}
                    className={s.create_referral_button}
                    >
                    Создать реферальную ссылку
              </button>
            </li>
            
          ))}
        </ul>
      ) : (
        <p>Нет доступных офферов.</p>
      )}
      </div>
      <div>
        <h2>Твои реферальные ссылки:</h2>
        {referallinks.length > 0 ? (
            <ul>
            {referallinks.map((referallink) => (
                <li key={referallink.id} class={s.ref_li}>
                  <div>
                  {/* Если ссылка в режиме редактирования */}
                  {editId === referallink.id ? (
                    <input
                      type="text"
                      value={newRefName}
                      onChange={(e) => setNewRefName(e.target.value)}
                      className={s.ref_input}
                    />
                  ) : (
                    <p>Название ссылки: {referallink.ref_name || "Назовите ссылку"}</p>
                  )}
                   {/* Блок редактирования */}
                   {editId === referallink.id ? (
                    <>
                      <button
                        className={s.ref_button}
                        onClick={() => {
                          updateRefName(referallink.id, newRefName);
                          setEditId(null); // Выходим из режима редактирования
                        }}
                      >
                        Сохранить
                      </button>
                      <button className={s.ref_button} onClick={() => setEditId(null)}>
                        Отмена
                      </button>
                    </>
                  ) : (
                    <button
                      className={s.ref_button}
                      onClick={() => {
                        setEditId(referallink.id);
                        setNewRefName(referallink.ref_name || "");
                      }}
                    >
                     Назвать
                    </button>
                  )}


                   <p>оффер: {referallink.name}</p> 
                  </div>
                
                <p>
                    Сайт: 
                    <a href={referallink.url} target="_blank" rel="noopener noreferrer">
                    {referallink.url}
                    </a>
                </p>
                <div class={s.div_ref_buttons}>
                  <button class={s.ref_button}
                        onClick={() => {
                        navigator.clipboard.writeText(referallink.url);
                        }}
                    >
                        Копировать ссылку
                    </button>

                  
                    <button class={s.ref_button}
                        onClick={() => deleteReferralLink(referallink.id)}
                        className={s.create_referral_button}
                        >
                        Удалить ссылку
                  </button>

                </div>
                
                </li>
            ))}
            </ul>
        ) : (
            <p>Нет активных реферальных ссылок.</p>
        )}
        </div>

    </div>
    
  );
};

export default OffersList;
