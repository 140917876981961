import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { DateContext } from "../DateContext";
import s from "../styles/PartnerStatisticsTable.module.css";

const PartnerStatisticsTable = () => {
  const { startDate, endDate } = useContext(DateContext);
  const [data, setData] = useState([]);
  const [expandedDate, setExpandedDate] = useState(null);
  const [dailyDetails, setDailyDetails] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const params = { start_date: startDate, end_date: endDate };
        const response = await axios.get("/api/partner_stats", { params });
        const serverData = response.data;

        // Фильтруем только строки, где есть хотя бы одно ненулевое значение
        const filteredData = serverData.filter(item =>
          [item.value, item.value2, item.value3, item.value4, item.value5].some(val => val !== 0)
        );

        setData(filteredData);
      } catch (error) {
        console.error("Ошибка при загрузке данных", error);
      }
    };

    fetchData();
  }, [startDate, endDate]);

  const fetchDailyDetails = async date => {
    if (dailyDetails[date]) {
      setExpandedDate(date === expandedDate ? null : date);
      return;
    }
    try {
      const response = await axios.get(`/api/partner_stats/daily/${date}`);
      setDailyDetails(prevDetails => ({ ...prevDetails, [date]: response.data }));
      setExpandedDate(date);
    } catch (error) {
      console.error("Ошибка при загрузке деталей", error);
    }
  };

  return (
    <div className={s.scrollContainer}>
      <table className={s.statistics_table}>
        <thead>
          <tr>
            <th>Дата</th>
            <th>Рег.</th>
            <th>Оплат</th>
            <th>Сумма</th>
            <th>Доход</th>
            <th>Подробности</th>
          </tr>
        </thead>
        <tbody>
          {data.map(item => (
            <React.Fragment key={item.date}>
              <tr>
                <td>{item.date}</td>
                <td>{item.value}</td>
                <td>{item.value2}</td>
                <td>{item.value3}</td>
                <td>{item.value4}</td>
                <td>
                  <button className={s.details_button} onClick={() => fetchDailyDetails(item.date)}>
                    {expandedDate === item.date ? "Скрыть" : "Показать"}
                  </button>
                </td>
              </tr>
              {expandedDate === item.date && dailyDetails[item.date] && (
              <tr className={s.details_row}>
                <td colSpan="7">
                  <div class={s.details_content}>
                    {Object.keys(dailyDetails[item.date]).length > 0 ? (
                      Object.entries(dailyDetails[item.date]).map(([clientId, actions]) => (
                        <div key={clientId} className={s.client_block}>
                          <p class={s.client_p}>Клиент: {clientId}</p>
                          <table className={s.details_table}>
                            <thead>
                              <tr>
                                <th>Тип действия</th>
                                <th>Оффер</th>
                                <th>Реф. код</th>
                                <th>Назв. реф.</th>
                                <th>Сумма</th>
                                <th>Доход</th>
                                <th>Время</th>
                              </tr>
                            </thead>
                            <tbody>
                              {actions.map((action, index) => (
                                <tr key={index}>
                                  <td>{action.action_type}</td>
                                  <td>{action.offer_id}</td>
                                  <td>{action.referral_code}</td>
                                  <td>{action.ref_name}</td>
                                  <td>{action.amount}</td>
                                  <td>{action.dohod}</td>
                                  <td>{action.time}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ))
                    ) : (
                      <p>Нет данных</p>
                    )}
                  </div>
                </td>
              </tr>
            )}

            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PartnerStatisticsTable;
