import React, {useState } from 'react';
import s from '../styles/PartnerDashboard.module.css';
import PartnerStatistics from './PartnerStatistics';
import PartnerStatisticsTable from './PartnerStatisticsTable';
import OffersList from './Offers';



const PartnerDashboard = () => {
    const [activeTab, setActiveTab] = useState('offers');


    const renderContent = () => {
        switch (activeTab) {
            case 'statistics':
                return <Statistics />;
            case 'referrals':
                return <Referrals />;
            case 'payments':
                return <Payments />;
            case 'offers':
                return <Offers />;
            case 'profile':
                return <Profile />;
            default:
                return <Offers />;
        }
    };

    return (
        <div className={s.partner_dashboard}>
            <div className={s.sidebar}>
                <ul>
                <li
                        className={activeTab === 'Offers' ? 'active' : ''}
                        onClick={() => setActiveTab('offers')}
                    >
                        Офферы
                    </li>
                    <li
                        className={activeTab === 'statistics' ? 'active' : ''}
                        onClick={() => setActiveTab('statistics')}
                    >
                        Статистика
                    </li>
                    {/* <li
                        className={activeTab === 'referrals' ? 'active' : ''}
                        onClick={() => setActiveTab('referrals')}
                    >
                        Рефералы
                    </li> */}
                    <li
                        className={activeTab === 'payments' ? 'active' : ''}
                        onClick={() => setActiveTab('payments')}
                    >
                        Выплаты
                    </li>
                    <li
                        className={activeTab === 'profile' ? 'active' : ''}
                        onClick={() => setActiveTab('profile')}
                    >
                        Профиль
                    </li>
                </ul>
            </div>
            <div className={s.content}>
                {renderContent()}
            </div>
        </div>
    );
};

const Statistics = () => (
    <>
    <h3>Статистика</h3>
    <div>
        <PartnerStatistics/>
    </div>
    <div>
        <PartnerStatisticsTable/>
    </div>    
    </>

);

const Referrals = () => (
    <div>
        <h2>Рефералы</h2>
    </div>
    
);

const Payments = () => (
    <div>
        <h2>Выплаты</h2>
        <p>Отслеживайте свои выплаты и запросы на получение средств.</p>
    </div>
);

const Profile = () => (
    <div>
        <h2>Профиль</h2>
        <p>Редактируйте информацию о своём профиле и настройках аккаунта.</p>
    </div>
);

const Offers = () => (
    <div>
        <h2>Офферы</h2>
        <p>Выберайте Офферы  и управляйте своими реферальными ссылками.</p>
        <div>
            <OffersList/>
        </div>
    </div>
    
);

export default PartnerDashboard;
